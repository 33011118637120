import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Biogeografické šíření druhů?" />
    <h1>Biogeografické šíření druhů</h1>

    <p>Biogeografick&eacute; &scaron;&iacute;řen&iacute; druhů je <Link to="/biogeografie/disperze/">typ disperze</Link>, při n&iacute;ž druh kolonizuje nov&eacute; &uacute;zem&iacute; a vede tak zvět&scaron;en&iacute; are&aacute;lu druhu. K &uacute;spě&scaron;n&eacute; kolonizaci (nebo tak&eacute; biogeografick&eacute; disperzi) jsou nutn&eacute; 3 z&aacute;kladn&iacute; kroky:</p>
    <ol>
    <li>dostat se na nov&eacute; &uacute;zem&iacute;</li>
    <li>přež&iacute;t tamn&iacute; podm&iacute;nky prostřed&iacute;</li>
    <li>rozmnožit se a založit novou životaschopnou populaci</li>
    </ol>
    <hr />
    <h2>Typy biogeografick&eacute; disperze</h2>
    <p>Existuj&iacute; 2 z&aacute;kladn&iacute; druhy biogeografick&eacute; disperze (či biogeografick&eacute;ho &scaron;&iacute;řen&iacute;), a to:</p>
    <ul>
    <li>skokov&eacute; &scaron;&iacute;řen&iacute; na velk&eacute; vzd&aacute;lenosti (jump dispersal)</li>
    <li>dif&uacute;zn&iacute; &scaron;&iacute;řen&iacute; (diffusion)</li>
    </ul>
    <h3>Skokov&eacute; &scaron;&iacute;řen&iacute; na velk&eacute; vzd&aacute;lenosti</h3>
    <p>Do skokov&eacute;ho &scaron;&iacute;řen&iacute; na velk&eacute; vzd&aacute;lenosti (anglicky <em>jump dispersal</em>) spadaj&iacute; zejm&eacute;na os&iacute;dlen&iacute; ostrovů či překročen&iacute; pou&scaron;t&iacute; a hor. Děje se vět&scaron;inou pasivně, a to za pomoci:</p>
    <ul>
    <li>větru (pt&aacute;ci jsou j&iacute;m strženi)</li>
    <li>tekouc&iacute; vody (např. vory s legu&aacute;ny)</li>
    <li>mobilněj&scaron;&iacute;ch organismů (např. vodn&iacute; bezobratl&iacute; se mohou &scaron;&iacute;řit d&iacute;ky pt&aacute;kům)</li>
    </ul>
    <p>Mezi zv&yacute;hodněn&eacute; taxony při skokov&eacute; disperzi patř&iacute; zejm&eacute;na takov&eacute;, kter&eacute;:</p>
    <ul>
    <li>maj&iacute; pasivn&iacute; st&aacute;dia adaptovan&eacute; k disperzi</li>
    <li>jsou vysoce tolerantn&iacute; k podm&iacute;nk&aacute;m prostřed&iacute; (např. tolerance sucha, mořsk&eacute; vody)</li>
    </ul>
    <h3>Dif&uacute;zn&iacute; &scaron;&iacute;řen&iacute;</h3>
    <p>Dif&uacute;zn&iacute; &scaron;&iacute;řen&iacute; (anglicky <em>diffusion</em>) je pojem pro postupnou kolonizaci od okrajů současn&eacute;ho are&aacute;lu. Často k n&iacute; doch&aacute;z&iacute; po skokov&eacute;m &scaron;&iacute;řen&iacute; na velk&eacute; vzd&aacute;lenosti a kolonizaci &uacute;plně nov&eacute;ho &uacute;zem&iacute;.</p>
<hr />
    <h2>Koridory a filtry</h2>
    <p><strong>Koridory</strong> umožňuj&iacute; disperzi a pohyb obecně mezi 2 oblastmi, a to neselektivně pro velkou č&aacute;st či vět&scaron;inu taxonů (mohou se &scaron;&iacute;řit cel&aacute; společenstva).</p>
    <p><strong>Filtry</strong> umožňuj&iacute; disperzi jen men&scaron;&iacute; č&aacute;sti druhů, jsou č&aacute;stečně selektivn&iacute; vůči určit&yacute;m taxonům. Podm&iacute;nky v t&eacute;to přechodn&eacute; oblasti jsou totiž v&iacute;ce či m&eacute;ně odli&scaron;n&eacute; od poč&aacute;tečn&iacute; a koncov&eacute; oblasti. Druhy s vy&scaron;&scaron;&iacute; <Link to="/biogeografie/ekologicka/ekologicke-faktory/">ekologickou amplitudou</Link> maj&iacute; obecně vy&scaron;&scaron;&iacute; pravděpodobnost, že je filtr propust&iacute; na druhou stranu a t&iacute;m mu umožn&iacute; biogeografickou disperzi.</p>
    <h3>Velk&aacute; americk&aacute; v&yacute;měna</h3>
    <p>Velk&aacute; americk&aacute; v&yacute;měna je pojem označuj&iacute;c&iacute; v&yacute;měnu druhů mezi Severn&iacute; a Jižn&iacute; Amerikou, kter&aacute; proběhla po tom, co se oba kontinenty spojily. Nově vznikl&aacute; Panamsk&aacute; &scaron;&iacute;je (st&aacute;ř&iacute; zhruba 3,5 miliony let) se pak choval jako filtr umožňuj&iacute; selektivn&iacute; disperzi.</p>
    <p>Zat&iacute;mco někter&eacute; druhy byly zastaveny filtrem (Panamsk&aacute; &scaron;&iacute;je a oblast Středn&iacute; Ameriky), jin&eacute; (jako např. kočky, kr&aacute;l&iacute;ci či nutrie) byly filtrem propu&scaron;těny.</p>
    <p>Obecně se prok&aacute;zali severoameričt&iacute; savci jako &uacute;spě&scaron;něj&scaron;&iacute;, a to z několika důvodů:</p>
    <ol>
    <li>lep&scaron;&iacute; migračn&iacute; dovednosti (dostali se až do středu Jižn&iacute; Ameriky, zat&iacute;mco jihoamerick&eacute; druhy vět&scaron;inou jen do Mexika)</li>
    <li>lep&scaron;&iacute; přizpůsoben&iacute; nov&eacute;mu prostřed&iacute;</li>
    <li>lep&scaron;&iacute; konkurenčn&iacute; schopnosti</li>
    <li>pravděpodobn&yacute; negativn&iacute; vliv změn klimatu (&uacute;stup de&scaron;tn&yacute;ch pralesů a &scaron;&iacute;řen&iacute; savan), nemoc&iacute;, parazitů a pred&aacute;torů na jihoamerick&eacute; druhy</li>
    </ol>
    <h3>N&aacute;&scaron;lapn&eacute; kameny</h3>
    <p>N&aacute;&scaron;lapn&eacute; kameny (anglicky <em>stepping stones</em>) jsou v biogeografick&eacute;m &scaron;&iacute;řen&iacute; definov&aacute;ny jako typ filtru. Obvykle jde o s&eacute;rii ostrovů. Takov&yacute; filtr je selektivn&iacute; pro druhy, kter&eacute; jsou schopny překonat bari&eacute;ry, v př&iacute;padě ostrovů plochu moře.</p>
    <h3>N&aacute;hodn&eacute; &scaron;&iacute;řen&iacute;</h3>
    <p>N&aacute;hodn&eacute; &scaron;&iacute;řen&iacute; (anglicky <em>sweeptakes routes</em>) je v biogeografii označen&iacute; pro vz&aacute;cnou a n&aacute;hodnou disperzi přes v&yacute;znamnou bari&eacute;ru, j&iacute;mž je např&iacute;klad dosažen&iacute; ostrova či kontinentu na raftu z padl&eacute;ho stromu. Doch&aacute;z&iacute; pak k tzv. skokov&eacute;mu &scaron;&iacute;řen&iacute; na velk&eacute; vzd&aacute;lenosti.</p>
    <p>Často jde o velmi nepravděpodobn&eacute; ud&aacute;losti, nikoliv v&scaron;ak nemožn&eacute;. Kupř&iacute;kladu i sloni a buvoli byli pozorov&aacute;n&iacute; v moři 50&nbsp;km od pevniny. Zde <a href="https://www.youtube.com/watch?v=p7XMHNdY2Ss" target="_blank" rel="noreferrer noopener">video</a> z&aacute;chrany slona, kter&yacute; byl nalezen 16&nbsp;km od pobřež&iacute;. V oce&aacute;nu byli spatřeni např&iacute;klad i velbloudi.</p>
    <h3>Bari&eacute;ry disperze</h3>
    <p>Při disperzi se uplatňuj&iacute; tyto tři z&aacute;kladn&iacute; druhy bari&eacute;r, kter&eacute; se chovaj&iacute; jako filtr vůči určit&yacute;m organismům:</p>
    <ul>
    <li><strong>fyziologick&eacute; bari&eacute;ry</strong> (teplota, salinita, vlhkost)</li>
    <li><strong>ekologick&eacute; bari&eacute;ry</strong> (predace, kompetice)</li>
    <li><strong>psychologick&eacute; bari&eacute;ry</strong> (vazba na typ habitatu, např. lesn&iacute; pt&aacute;ci)</li>
    </ul>
    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Biogeografické šíření druhů</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/disperze/ekologicke-sireni/"><button className="inv">&larr; Ekologické šíření druhů</button></Link>
    <Link to="/biogeografie/areografie/"><button className="inv">Areografie &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
